@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

/* base styles */
* {
  margin: 0;
  font-family: "Quicksand";
  color: #333;
}
.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  border-bottom: 1px solid #f2f2f2;
}
.navbar h1 {
  color: #f1356d;
}
.navbar .links {
  margin-left: auto;
}
.navbar a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
}
.navbar a:hover {
  color: #f1356d;
}
.content {
  max-width: 700px;
  margin: 40px auto;
  padding: 20px;
  height: calc(100vh - 28rem);
  overflow-y: auto;
  min-height: 54vh;
}

button {
  padding: 8px;
  border: none;
  margin: 5px;
  border-radius: 10px;
  text-decoration: none;
}

button a {
  text-decoration: none;
}

button:hover {
  background-color: #f1356d;
  color: white;
}

button:hover a{
  color: white;
}

.allCountriesContainer {
  display: flex;
  min-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.allCountriesList {
  height: calc(100vh - 30rem);
  overflow-y: auto;
  min-width: 350px;
  height: 30rem;
}


::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(151, 98, 98, 0.3);
  background-color: rgba(241, 53, 109, 0.8);
  opacity: 0.5;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(239, 239, 239);
  box-shadow: inset 0 0 6px rgba(151, 98, 98, 0.3);
  border-radius: 100px;
}

.holidays {
  min-width: 350px;
  /* height: calc(100vh - 28rem); */
  /* overflow-y: auto; */
}

button:hover {
  cursor: pointer;
}

.Search {
  width: 300px;
  margin: 0 auto;
  position: relative;
  display: flex;
}
.SearchSpan {
  width: 15%;
  border: 1px solid #f1356d;
  background: #f1356d;
  padding-top: 4px;
  text-align: center;
  color: #fff;
  border-radius: 5px 0 0 5px;
  font-size: 20px;
}
.SearchInput {
  width: 75%;
  border: 3px solid #f1356d;
  border-left: none;
  padding: 5px;
  border-radius: 0 5px 5px 0;
  outline: none;
}

